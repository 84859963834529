<template>
  <mobile-page title="" sub_title="Pick up vegetables at farm">
    <template v-slot:content>
      <div v-if="message !== null" class="message">{{message}}</div>
      <form v-else @submit.prevent="validate">
        <div class="pd-15 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs mb-2" v-html="$ExtrinsicLink(extrinsic)"></div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Crate Id</h6>
              <p class="my-0 font-weight-normal">{{crateid}}</p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">Harvest Location</h6>
              <p class="my-0 font-weight-normal">
                {{pickup.location.lat | unitFix(7)}}, {{pickup.location.lng | unitFix(7)}}
              </p>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <input class="form-control" placeholder="Farming Community" type="text" v-model="pickup.farming_community" :class="{ 'border-danger' : submitted && errors.has('farming_community') }" data-vv-as="Farming Community" name="farming_community" v-validate="'required'">
              <div class="text-danger" v-if="submitted && errors.has('farming_community')">{{errors.first('farming_community')}}</div>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <select class="form-control pl-2" v-model="pickup.vegetable" :class="{ 'border-danger' : submitted && errors.has('vegetable') }" data-vv-as="Vegetable" name="vegetable" v-validate="'required'">
                <option :value="null">Select Vegetable</option>
                <option v-for="vegetable in commodities" :value="vegetable">{{vegetable}}</option>
              </select>
              <div class="text-danger" v-if="submitted && errors.has('vegetable')">{{errors.first('vegetable')}}</div>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <input class="form-control bg-white" id="harvestdate" placeholder="Harvest Date" type="text" readonly v-model="pickup.harvest_date" :class="{ 'border-danger' : submitted && errors.has('harvest_date') }" data-vv-as="Harvest Date" name="harvest_date" v-validate="'required|date_format:yyyy-MM-dd'">
              <div class="text-danger" v-if="submitted && errors.has('harvest_date')">{{errors.first('harvest_date')}}</div>
            </div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12 input-group">
              <input class="form-control" placeholder="Pulp Temperature" type="number" step=".01" v-model="pickup.pulp_temperature" :class="{ 'border-danger' : submitted && errors.has('pulp_temperature') }" data-vv-as="Pulp Temperature" name="pulp_temperature" v-validate="'required|min_value:0|max_value:50'">
              <div class="input-group-append">
                <span class="input-group-text bg-gray-100" :class="{ 'border-danger' : submitted && errors.has('pulp_temperature') }">
                  °C
                </span>
              </div>
            </div>
            <div class="text-danger" v-if="submitted && errors.has('pulp_temperature')">{{errors.first('pulp_temperature')}}</div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12 input-group">
              <input class="form-control" placeholder="Vegetable Weight" type="number" step=".001" v-model="pickup.weight" :class="{ 'border-danger' : submitted && errors.has('weight') }" data-vv-as="Weight" name="Weight" v-validate="'required|min_value:.01'">
              <div class="input-group-append">
                <span class="input-group-text bg-gray-100" :class="{ 'border-danger' : submitted && errors.has('weight') }">
                  Kg
                </span>
              </div>
            </div>
            <div class="text-danger" v-if="submitted && errors.has('weight')">{{errors.first('weight')}}</div>
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <input class="form-control" placeholder="PO #" type="text" v-model="pickup.po_number" :class="{ 'border-danger' : submitted && errors.has('po_number') }" data-vv-as="PO Number" name="po_number" v-validate="'required'">
              <div class="text-danger" v-if="submitted && errors.has('po_number')">{{errors.first('po_number')}}</div>
            </div>
          </div>
          <div class="row row-xs">
            <div class="ml-auto col-md-2 col-sm-12">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                <i class="fa fa-check-circle" /> Sign
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </mobile-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "PickUp",
  props: ["crateid", "registryid", "processdefinitionid", "processid", "process_steps", "extrinsic"],
  data() {
    return {
      submitted: false,
      pickup: {

        location: {
          lat: null,
          lng: null
        },
        farming_community: null,
        vegetable: null,
        harvest_date: null,
        pulp_temperature: null,
        po_number: null,
        weight: null,

      },
      message: null,
      commodities: [
        'Amaranth',
        'Arum',
        'Ash Gourd',
        'Babycorn',
        'Banana Stem',
        'Beans',
        'Beetroot',
        'Bitter Gourd - Karala',
        'Bitter Gourd - Ucche',
        'Bottle Gourd - Rounded',
        'Brinjal - Garia',
        'Brinjal - Makra',
        'Broad beans',
        'Broccoli',
        'Cabbage',
        'Capsicum - Green',
        'Capsicum - Red and Yellow',
        'Carrot - Chalani',
        'Carrot - Desi',
        'Cauliflower',
        'Cauliflower',
        'Cauliflower',
        'Ceylon Spinach',
        'Colocacia',
        'Coriander Leaves',
        'Cucumber - Desi',
        'Curry leaves',
        'Drum Stick',
        'Fenugreek leaves',
        'Garlic - Flower Variety',
        'German Turnip',
        'Ginger - Bold',
        'Ginger - New',
        'Green Chilli - Bullet',
        'Green Chilli - Local',
        'Green Mango',
        'Green Papaya',
        'Green Peas',
        'Hogplum',
        'Ivy Gourd',
        'Kalmi Shak',
        'Ladys Finger',
        'Lime - Gandharaj',
        'Lime - Pati Lebu',
        'Mint',
        'Muri - Lalat',
        'Mushroom - Button',
        'Mushroom - Oyester',
        'Onion - Madyapradesh',
        'Onion - Nasik',
        'Onion - Sukhsagar',
        'Onion flower stalk',
        'Plantain Flower',
        'Plantain Flower',
        'Pointed Gourd',
        'Pointed Gourd - White',
        'Potato - Chandramukhi',
        'Potato - Jyoti',
        'Pumpkin - Ripe',
        'Radish',
        'Red Amaranth',
        'Ridge Gourd',
        'Snake Gourd',
        'Spinach',
        'Spine Gourd',
        'Sponge Gourd',
        'Spring Onion',
        'String Beans',
        'Sweetcorn',
        'Tamarind',
        'Tomato - Chalani',
        'Turmeric',
        'Veg Banana'
      ],
      location_error: null
    };
  },
  mounted() {
    $('#harvestdate')
      .datepicker({
        dateFormat: 'yy-mm-dd',
        startDate: 'd'
      })
      .change('data', (e) => {
        this.pickup.harvest_date = e.target.value;
      });

    this.getCurrentLocation();

    this.getProcessSteps();

  },
  methods: {
    async getCurrentLocation() {
      try {
        this.pickup.location = await this.$getLocation({ enableHighAccuracy: true, timeout: Infinity, maximumAge: 0 });
      } catch (e) {
        this.location_error = e;
        this.$toastr.e('Cannot access location', 'Error');
      }
    },
    async getProcessSteps() {

      if (typeof this.process_steps != 'undefined') {

      } else {
        this.$toastr.e('Please rescan barcode', 'Error');
        this.message = "Please rescan barcode";
      }
    },
    async validate() {
      this.submitted = true;
      let valid = await this.$validator.validate();

      if (valid) {
        await this.saveProcessStep();
      }
    },
    async saveProcessStep() {

      if (typeof this.processid != 'undefined') {

        try {
          EventBus.$emit('openLoader');
          let attributes = [
            {
              name: "Location Lat",
              type: "String",
              value: `${this.pickup.location.lat}`
            },
            {
              name: "Location Lng",
              type: "String",
              value: `${this.pickup.location.lng}`
            },
            {
              name: "Farming Community",
              type: "String",
              value: this.pickup.farming_community
            },
            {
              name: "Vegetable",
              type: "String",
              value: this.pickup.vegetable
            },
            {
              name: "Harvest Date",
              type: "Date",
              value: this.pickup.harvest_date
            },
            {
              name: "Pulp Temperature (°C)",
              type: "String",
              value: this.pickup.pulp_temperature
            },
            {
              name: "PO Number",
              type: "String",
              value: this.pickup.po_number
            },
            {
              name: "Weight (Kg)",
              type: "String",
              value: this.pickup.weight
            }
          ];
          let result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps`, {
            process_definition_step: this.process_steps[0].process_definition_step,
            attributes: attributes
          });

          let processstepid = result.data.id;
          result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/processes/${this.processid}/process_steps/${processstepid}/attest`, { process_definition_step_index: this.process_steps[0].order });

          this.$toastr.s("Details successfully submited. Thank you.", 'Success');
          this.$router.push({ name: 'summary', params: { crateid: this.crateid, registryid: this.registryid, processdefinitionid: this.processdefinitionid, processid: this.processid, process_steps: this.process_steps, extrinsic: this.extrinsic } });

        } catch (e) {
          console.log(e);
          this.message = "Error saving";
          this.$toastr.e('Error saving', 'Error');
        } finally {
          EventBus.$emit('closeLoader');
        }
      } else {
        this.$toastr.e('Please rescan barcode', 'Error');
        this.message = "Please rescan barcode";
      }
    }
  }
}
</script>

<style scoped>
.message {
  text-align: center;
  margin-top: 50px;
}
</style>